.App {
  text-align: center;
}

.App-logo {
  width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.App-header {
  background-color: #C50000;
  background-size: cover;
  height: 100px;
  padding: 20px;
  color: white;
}

.App-footer {
  background-size: cover;
  text-align: left;
  min-height: 20px;
  max-height: 190px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.rdr-Calendar {
  width: 290px !important;
}